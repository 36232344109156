.contact {
  display: flex;
  overflow: hidden;
  flex-direction: column-reverse;
  gap: 5px;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 10px;
  padding-bottom: 15px;
}
.sectionSubText {
  font-size: 14px;
  color: #958a8a;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sectionHeadText {
  color: #0d6efd;
  font-size: 30px;
  font-weight: 700;
}
@media (min-width: 800px) {
  .contact {
    margin-top: 3rem;
    flex-direction: row;
  }
}

.name_email {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}
.inputs_contact {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
  border-style: none;
  outline-style: none;
  font-weight: 500;
  color: #000;
  background-color: #fff;
}

.inputs_labels {
  margin-bottom: 1rem;
  font-weight: 500;
  color: #000;
  text-align: initial;
}
.submit_btn {
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 0.75rem;
  width: fit-content;
  font-weight: 700;
  color: #ffffff;
  background-color: #257ee4;
}
.submit_btn:hover {
  -webkit-box-shadow: 0px 0px 62px 11px rgba(37, 126, 228, 0.72);
  -moz-box-shadow: 0px 0px 62px 11px rgba(37, 126, 228, 0.72);
  box-shadow: 0px 0px 62px 11px rgba(37, 126, 228, 0.72);
}
.address {
  background: #e5e5e5;
  border-radius: 8px;
  padding: 20px 15px 30px 15px;
  flex: 1;
  min-width: 287px;
  color: #ebe3e3;
}
.address .addr {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}
.address h3,
.address h4 {
  font-weight: 700;
  color: #0d6efd;
}
.address h4 {
  margin-top: 30px;
  margin-bottom: 10px;
}
.address .addr p {
  margin-top: 16px;
  font-weight: 600;
  color: #000;
}
.address .addr img {
  width: 40px;
}
.socialicons {
  display: flex;
  gap: 4px;
  justify-content: center;
}
.socialicons a img {
  width: 50px;
  height: 50px;
}

@media (max-width: 600px) {
  .labels_container {
    width: 100% !important;
  }
}
