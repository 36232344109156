.heroSection {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  background-image: url(../../assets/materials/cameras-connectees-533235_1080x1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: revert;
}
.mainDesc {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: absolute;
  top: 260px;
  max-width: 7rem;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
@keyframes boucle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(10px);
  }
}
.heroicon {
  position: absolute;
  right: 20px;
  top: 160px;
  /* background: #6edfff;
  -webkit-box-shadow: 0px 0px 128px 37px rgba(46, 199, 255, 0.9);
  -moz-box-shadow: 0px 0px 128px 37px rgba(46, 199, 255, 0.9);
  box-shadow: 0px 0px 128px 37px rgba(46, 199, 255, 0.9);
  border-radius: 55% 45% 70% 30% / 39% 28% 72% 61%; */
  animation-name: boucle;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@media (max-width: 630px) {
  .heroicon {
    display: none;
  }
}
.mainDescBarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.barBall {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #0d6efd;
}
.barStick {
  width: 3px;
  height: 18rem;
}
.violet-gradient {
  background: #804dee;
  background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
  background: -webkit-linear-gradient(
    -90deg,
    #0d6efd 0%,
    rgba(60, 51, 80, 0) 100%
  );
}

.heroHeadText {
  font-weight: 900;
  color: white;
  font-size: 40px;
  margin-top: 0.5px;
}
.heroHeadText span {
  color: #0d6efd;
}
@media (min-width: 1024px) {
  .heroHeadText {
    font-size: 80px;
  }
}
@media (min-width: 768px) {
  .heroHeadText {
    font-size: 50px;
  }
  .heroHeadText {
    font-size: 80px;
    line-height: 98px;
  }
  .heroSubText {
    font-size: 30px;
  }
}
.heroSubText {
  color: #000;
  font-weight: 500;
  font-size: 26px;
  line-height: 40px;
}
@media (min-width: 640px) {
  .heroSubText {
    font-size: 20px;
  }
  .HeroBar {
    display: block;
  }
}
.flowBottom {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flowBottomMotion {
  width: 35px;
  height: 64px;
  border-radius: 3rem;
  border: 4px solid #000000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0.25rem;
}
.motionStyles {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #050404;
  margin-bottom: 1rem;
}
