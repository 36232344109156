@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  color-scheme: dark;
}

.hash-span {
  margin-top: -100px;
  padding-bottom: 100px;
  display: block;
}

.black-gradient {
  background: #000000; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.violet-gradient {
  background: #804dee;
  background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
  background: -webkit-linear-gradient(
    -90deg,
    #804dee 0%,
    rgba(60, 51, 80, 0) 100%
  );
}

.green-pink-gradient {
  background: "#00cea8";
  background: linear-gradient(90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
  background: -webkit-linear-gradient(-90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
}

.orange-text-gradient {
  background: #f12711; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #f12711,
    #f5af19
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green-text-gradient {
  background: #11998e; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #11998e,
    #38ef7d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue-text-gradient {
  /* background: -webkit-linear-gradient(#eee, #333); */
  background: #56ccf2; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #2f80ed,
    #56ccf2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pink-text-gradient {
  background: #ec008c; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #ec008c,
    #fc6767
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* canvas- styles */
.canvas-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.heroSection {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  background-image: url(/static/media/cameras-connectees-533235_1080x1.840fb3e7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: revert;
}
.mainDesc {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: absolute;
  top: 260px;
  max-width: 7rem;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
}
@keyframes boucle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(10px);
  }
}
.heroicon {
  position: absolute;
  right: 20px;
  top: 160px;
  /* background: #6edfff;
  -webkit-box-shadow: 0px 0px 128px 37px rgba(46, 199, 255, 0.9);
  -moz-box-shadow: 0px 0px 128px 37px rgba(46, 199, 255, 0.9);
  box-shadow: 0px 0px 128px 37px rgba(46, 199, 255, 0.9);
  border-radius: 55% 45% 70% 30% / 39% 28% 72% 61%; */
  animation-name: boucle;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@media (max-width: 630px) {
  .heroicon {
    display: none;
  }
}
.mainDescBarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.barBall {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #0d6efd;
}
.barStick {
  width: 3px;
  height: 18rem;
}
.violet-gradient {
  background: #804dee;
  background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
  background: -webkit-linear-gradient(
    -90deg,
    #0d6efd 0%,
    rgba(60, 51, 80, 0) 100%
  );
}

.heroHeadText {
  font-weight: 900;
  color: white;
  font-size: 40px;
  margin-top: 0.5px;
}
.heroHeadText span {
  color: #0d6efd;
}
@media (min-width: 1024px) {
  .heroHeadText {
    font-size: 80px;
  }
}
@media (min-width: 768px) {
  .heroHeadText {
    font-size: 50px;
  }
  .heroHeadText {
    font-size: 80px;
    line-height: 98px;
  }
  .heroSubText {
    font-size: 30px;
  }
}
.heroSubText {
  color: #000;
  font-weight: 500;
  font-size: 26px;
  line-height: 40px;
}
@media (min-width: 640px) {
  .heroSubText {
    font-size: 20px;
  }
  .HeroBar {
    display: block;
  }
}
.flowBottom {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flowBottomMotion {
  width: 35px;
  height: 64px;
  border-radius: 3rem;
  border: 4px solid #000000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0.25rem;
}
.motionStyles {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #050404;
  margin-bottom: 1rem;
}

:root {
  --secondary-color: #958a8a; /* Replace with your desired color value */
}
.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  top: 0;
  z-index: 20;
}
.bg-primary {
  background-color: #1b1616c9 !important;
}
.bg-transparent {
  background-color: transparent;
}
.navContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* max-width: 70rem; */
  margin: 0;
  padding: 0px 11px;
  border-radius: 0 0 8px 8px;
  background: #000 !important;
  box-shadow: -1px -4px 20px 18px #817575;
}
.logoLink {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
  text-decoration: none;
}
.logoDesc {
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
}
.myLogo {
  width: 15.25rem;
  height: 5.25rem;
  object-fit: contain;
}

.listMenu {
  list-style: none;
  display: flex;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  align-items: center;
  margin-right: 60px;
}
@media screen and (max-width: 640px) {
  .listMenu {
    display: none;
  }
}
.hiddenMenu {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1 1;
  grid-gap: 2rem;
  gap: 2rem;
}
.toggleMenu {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 640px) {
  .toggleMenu {
    display: none;
  }
}
.hiddenMenuContainer {
  /* padding: 2rem;
  position: absolute;
  top: 4rem;
  right: 0;
  margin-right: 0;
  margin-top: 1rem;
  min-width: 191px;
  height: 231px;
  z-index: 10;
  border-radius: 1rem;
  padding-left: 0px; */
  padding: 2rem;
  position: absolute;
  top: 4.5rem;
  left: 0;
  margin-right: 0;
  height: 231px;
  width: 100%;
  z-index: 10;
  padding-left: 0px;
  background: #000 !important;
}
.hidden {
  display: none;
}
.flex {
  display: flex;
}
.black-gradient {
  background: #000000; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.toggleIcon {
  width: 28px;
  height: 28px;
  object-fit: contain;
  cursor: pointer;
}
.activeLink {
  color: rgb(64 255 239);
}
.notActiveLink {
  color: white;
}
.notActiveLink:hover {
  color: rgb(64 255 239);
  box-shadow: 6px rgb(64 255 239);
}
.menulinks {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
.menulinks:hover {
  color: white;
}
.hiddenLinks {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}

.sectionSubText {
  font-size: 14px;
  color: #958a8a;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sectionHeadText {
  color: #0d6efd !important;
  font-size: 30px;
}
.description {
  margin-top: 1rem;
  color: #000;
  font-size: 17px;
  width: 100%;
  line-height: 30px;
  padding: 12px;
  text-align: center;
  opacity: 1;
}

.description .company-name {
  color: #1162cc;
  font-weight: bolder;
  font-size: 20px;
}

.serviceCardContainer {
  width: 100%;
}
.serviceCards {
  margin-top: 20px;
  display: grid;
  padding: 70px;
  grid-gap: 2rem;
  /* align-items: center; */
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
}
.serviceCard {
  background-color: #000;
  width: 100%;
  padding: 1px;
  border-radius: 20px;
  opacity: 1;
}
@media (min-width: 600px) {
  .serviceCards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .serviceCards {
    grid-template-columns: repeat(3, 1fr);
  }
}
.green-pink-gradient {
  background: "#00cea8";
  background: linear-gradient(90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
  background: -webkit-linear-gradient(-90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
}
.serviceCardContent {
  background-color: rgb(9 49 56);
  /* background-blend-mode: multiply; */
  border-radius: 20px;
  padding: 1rem 2rem;
  min-height: 280px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.serviceCardContent:hover {
  background-blend-mode: normal;
  cursor: pointer;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.serviceIcon {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.serviceTitle {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.sectionSubText {
  font-size: 14px;
  color: #958a8a;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sectionHeadText {
  color: #0d6efd !important;
  font-size: 30px;
}
.exIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.exIcon {
  width: 60%;
  height: 60%;
  object-fit: contain;
}
.exTitle {
  color: #2271e5;
  font-size: 24px;
  font-weight: bold;
}
.exCompany {
  color: #958a8a;
  font-size: 16px;
  font-weight: 500;
}
.exPointContainer {
  margin-top: 0.5rem;
  list-style-type: disc;
  margin-left: 0.5rem;
}

.exPoint {
  color: #000;
  font-size: 14px;
  padding-left: 1px;
  letter-spacing: normal;
}

.work {
  display: flex;
  padding: 10px;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  flex-wrap: wrap;
}
.map {
  border-radius: 8px;
  flex: 2 1;
  background: rgb(224, 213, 213);
}
.map iframe {
  border-radius: 8px;
}

.houres h3 {
  font-weight: 700;
  color: #0d6efd;
}

.houres {
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  padding: 20px 15px 30px 15px;
  border-radius: 8px;
  flex: 1 1;
  min-width: 287px;
  color: #ebe3e3;
}

.houres .days {
  display: flex;
  justify-content: space-between;
}

.houres .days p {
  font-weight: 600;
  color: #000;
}
.houres .dayoff p {
  color: rgb(170, 12, 12);
}

@media (max-width: 700px) {
  .work {
    flex-direction: column;
  }
}

.contact {
  display: flex;
  overflow: hidden;
  flex-direction: column-reverse;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 10px;
  padding-bottom: 15px;
}
.sectionSubText {
  font-size: 14px;
  color: #958a8a;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sectionHeadText {
  color: #0d6efd;
  font-size: 30px;
  font-weight: 700;
}
@media (min-width: 800px) {
  .contact {
    margin-top: 3rem;
    flex-direction: row;
  }
}

.name_email {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
}
.inputs_contact {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
  border-style: none;
  outline-style: none;
  font-weight: 500;
  color: #000;
  background-color: #fff;
}

.inputs_labels {
  margin-bottom: 1rem;
  font-weight: 500;
  color: #000;
  text-align: initial;
}
.submit_btn {
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 0.75rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 700;
  color: #ffffff;
  background-color: #257ee4;
}
.submit_btn:hover {
  box-shadow: 0px 0px 62px 11px rgba(37, 126, 228, 0.72);
}
.address {
  background: #e5e5e5;
  border-radius: 8px;
  padding: 20px 15px 30px 15px;
  flex: 1 1;
  min-width: 287px;
  color: #ebe3e3;
}
.address .addr {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}
.address h3,
.address h4 {
  font-weight: 700;
  color: #0d6efd;
}
.address h4 {
  margin-top: 30px;
  margin-bottom: 10px;
}
.address .addr p {
  margin-top: 16px;
  font-weight: 600;
  color: #000;
}
.address .addr img {
  width: 40px;
}
.socialicons {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  justify-content: center;
}
.socialicons a img {
  width: 50px;
  height: 50px;
}

@media (max-width: 600px) {
  .labels_container {
    width: 100% !important;
  }
}

.socialmediaicons {
  display: flex;

  grid-gap: 8px;

  gap: 8px;
  justify-content: center;
  align-items: center;
}
.socialmediaicons a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 3px;
}

.facebook {
  background: #1877f2;
  color: #fff !important;
}
.instagram {
  background: linear-gradient(
    29.61deg,
    #f38334 0%,
    #da2e7d 50.39%,
    #6b54c6 100%
  );
  color: #fff !important;
}
.linkedin {
  background: #336aea;
  color: #fff !important;
}
.tiktok {
  background: #fff;
  color: #000 !important;
}

.footer-desc {
  margin-top: -77px;
  color: #a5a5a5;
  font-size: 19px;
}

.footer-link {
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-top: 40px;
}

.footer-links ul li {
  margin: 15px;
}

.footer-links ul li a {
  color: #a5a5a5;
  text-decoration: none;
}
.row {
  background: black;
  border-radius: 67px 67px 0 0;
}

.footer-links ul li a:hover {
  color: #ffffff;
  text-decoration: none;
}
.f-copyright a {
  color: #4c9ada;
  text-decoration: none;
  margin-left: 30px;
  font-size: 17px;
  font-weight: 600;
}
.f-copyright a:hover {
  color: #fff;
}
.socialicons {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  justify-content: center;
}
.socialicons a img {
  width: 50px;
  height: 50px;
}

.floating-whatsApp {
  display: flex;
  flex: start 1;
}
@keyframes boucle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(10px);
  }
}
.floating-whatsApp a img {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  animation-name: boucle;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

.appContainer {
  background-color: #ffffff;
}
.appContainer::-webkit-scrollbar {
  width: 12px;
}

/* Thumb */
.appContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

/* Handle on hover */
.appContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Track */
.appContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle when the user drags it */
.appContainer::-webkit-scrollbar-thumb:active {
  background-color: #777;
}

