:root {
  --secondary-color: #958a8a; /* Replace with your desired color value */
}
.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  top: 0;
  z-index: 20;
}
.bg-primary {
  background-color: #1b1616c9 !important;
}
.bg-transparent {
  background-color: transparent;
}
.navContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* max-width: 70rem; */
  margin: 0;
  padding: 0px 11px;
  border-radius: 0 0 8px 8px;
  background: #000 !important;
  box-shadow: -1px -4px 20px 18px #817575;
}
.logoLink {
  display: flex;
  align-items: center;
  gap: 2px;
  text-decoration: none;
}
.logoDesc {
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
}
.myLogo {
  width: 15.25rem;
  height: 5.25rem;
  object-fit: contain;
}

.listMenu {
  list-style: none;
  display: flex;
  gap: 2.5rem;
  align-items: center;
  margin-right: 60px;
}
@media screen and (max-width: 640px) {
  .listMenu {
    display: none;
  }
}
.hiddenMenu {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1;
  gap: 2rem;
}
.toggleMenu {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 640px) {
  .toggleMenu {
    display: none;
  }
}
.hiddenMenuContainer {
  /* padding: 2rem;
  position: absolute;
  top: 4rem;
  right: 0;
  margin-right: 0;
  margin-top: 1rem;
  min-width: 191px;
  height: 231px;
  z-index: 10;
  border-radius: 1rem;
  padding-left: 0px; */
  padding: 2rem;
  position: absolute;
  top: 4.5rem;
  left: 0;
  margin-right: 0;
  height: 231px;
  width: 100%;
  z-index: 10;
  padding-left: 0px;
  background: #000 !important;
}
.hidden {
  display: none;
}
.flex {
  display: flex;
}
.black-gradient {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.toggleIcon {
  width: 28px;
  height: 28px;
  object-fit: contain;
  cursor: pointer;
}
.activeLink {
  color: rgb(64 255 239);
}
.notActiveLink {
  color: white;
}
.notActiveLink:hover {
  color: rgb(64 255 239);
  box-shadow: 6px rgb(64 255 239);
}
.menulinks {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
.menulinks:hover {
  color: white;
}
.hiddenLinks {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}
