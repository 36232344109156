.floating-whatsApp {
  display: flex;
  flex: start;
}
@keyframes boucle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(10px);
  }
}
.floating-whatsApp a img {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  animation-name: boucle;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
