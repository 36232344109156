.work {
  display: flex;
  padding: 10px;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}
.map {
  border-radius: 8px;
  flex: 2;
  background: rgb(224, 213, 213);
}
.map iframe {
  border-radius: 8px;
}

.houres h3 {
  font-weight: 700;
  color: #0d6efd;
}

.houres {
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 20px 15px 30px 15px;
  border-radius: 8px;
  flex: 1;
  min-width: 287px;
  color: #ebe3e3;
}

.houres .days {
  display: flex;
  justify-content: space-between;
}

.houres .days p {
  font-weight: 600;
  color: #000;
}
.houres .dayoff p {
  color: rgb(170, 12, 12);
}

@media (max-width: 700px) {
  .work {
    flex-direction: column;
  }
}
