.sectionSubText {
  font-size: 14px;
  color: #958a8a;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sectionHeadText {
  color: #0d6efd !important;
  font-size: 30px;
}
.description {
  margin-top: 1rem;
  color: #000;
  font-size: 17px;
  width: 100%;
  line-height: 30px;
  padding: 12px;
  text-align: center;
  opacity: 1;
}

.description .company-name {
  color: #1162cc;
  font-weight: bolder;
  font-size: 20px;
}

.serviceCardContainer {
  width: 100%;
}
.serviceCards {
  margin-top: 20px;
  display: grid;
  padding: 70px;
  grid-gap: 2rem;
  /* align-items: center; */
  gap: 2rem;
  justify-content: center;
}
.serviceCard {
  background-color: #000;
  width: 100%;
  padding: 1px;
  border-radius: 20px;
  opacity: 1;
}
@media (min-width: 600px) {
  .serviceCards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .serviceCards {
    grid-template-columns: repeat(3, 1fr);
  }
}
.green-pink-gradient {
  background: "#00cea8";
  background: linear-gradient(90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
  background: -webkit-linear-gradient(-90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
}
.serviceCardContent {
  background-color: rgb(9 49 56);
  /* background-blend-mode: multiply; */
  border-radius: 20px;
  padding: 1rem 2rem;
  min-height: 280px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.serviceCardContent:hover {
  background-blend-mode: normal;
  cursor: pointer;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.serviceIcon {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.serviceTitle {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
