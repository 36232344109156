.footer-desc {
  margin-top: -77px;
  color: #a5a5a5;
  font-size: 19px;
}

.footer-link {
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-top: 40px;
}

.footer-links ul li {
  margin: 15px;
}

.footer-links ul li a {
  color: #a5a5a5;
  text-decoration: none;
}
.row {
  background: black;
  border-radius: 67px 67px 0 0;
}

.footer-links ul li a:hover {
  color: #ffffff;
  text-decoration: none;
}
.f-copyright a {
  color: #4c9ada;
  text-decoration: none;
  margin-left: 30px;
  font-size: 17px;
  font-weight: 600;
}
.f-copyright a:hover {
  color: #fff;
}
.socialicons {
  display: flex;
  gap: 4px;
  justify-content: center;
}
.socialicons a img {
  width: 50px;
  height: 50px;
}
